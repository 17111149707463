import styled from "styled-components";

export const Container = styled.div`
  max-width: 800px;
  padding: 24px;
`;

export const Header = styled.div`
  font-family: Merriweather;
  font-size: 35px;
  font-weight: 900;
  text-align: center;
`;

export const HR = styled.hr``;

export const FormWrapper = styled.div`
  margin-top: 40px;
`;

export const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const NameWrapperCenter = styled(NameWrapper)`
  justify-content: center;
  margin-top: 30px;
`;

export const InputWrapper = styled.div`
  width: 48%;
`;
