import React, { FC } from "react";
import { ButtonC } from "./Button.style";

interface ButtonXProps {
  text: string;
  width?: string;
  fcolor?: string;
  bcolor?: string;
  onClick: () => void;
}

const ButtonX: FC<ButtonXProps> = ({
  text,
  width = "200px",
  fcolor = "#fff",
  bcolor = "#1677ff",
  onClick,
}) => {
  return (
    <ButtonC width={width} fcolor={fcolor} bcolor={bcolor} onClick={onClick}>
      {text}
    </ButtonC>
  );
};

export default ButtonX;
