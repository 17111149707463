import React, { createContext, FC, useState } from "react";

interface CJContextProps {
  children: React.ReactNode;
}

interface ContextValueTypes {
  fname: string;
  lname: string;
  email: string;
  metalChoice: string | undefined;
  goldKarat: string | undefined;
  imageUrls: string[] | undefined;
  resetForm: () => void;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setFname: React.Dispatch<React.SetStateAction<string>>;
  setLname: React.Dispatch<React.SetStateAction<string>>;
  setMetalChoice: React.Dispatch<React.SetStateAction<string | undefined>>;
  setGoldKarat: React.Dispatch<React.SetStateAction<string | undefined>>;
  setImageUrls: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

export const CustomJewelryContext = createContext<ContextValueTypes | null>(
  null
);

const { Provider } = CustomJewelryContext;

const CJContext: FC<CJContextProps> = ({ children }) => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [metalChoice, setMetalChoice] = useState<string | undefined>(undefined);
  const [goldKarat, setGoldKarat] = useState<string | undefined>(undefined);
  const [imageUrls, setImageUrls] = useState<string[] | undefined>();

  const resetForm = () => {
    setFname("");
    setLname("");
    setEmail("");
    setMetalChoice(undefined);
    setGoldKarat(undefined);
    setImageUrls(undefined);
  };

  const value: ContextValueTypes = {
    fname,
    lname,
    email,
    goldKarat,
    metalChoice,
    imageUrls,
    setImageUrls,
    setMetalChoice,
    setGoldKarat,
    setEmail,
    setFname,
    setLname,
    resetForm,
  };

  return <Provider value={value}>{children}</Provider>;
};

export default CJContext;
