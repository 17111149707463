import React, { useState, useContext, FC } from "react";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import axios from "axios";
import { CustomJewelryContext } from "../../organism/CustomJewelry/Context";
import { FileUploadContainer } from "./FileUpload.style";

const { Dragger } = Upload;

const toBase64 = (file: any) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (e) => reject(e);
  });
};

interface FileUploadProps {
  resetUpload: number;
}

const FileUpload: FC<FileUploadProps> = ({ resetUpload }) => {
  const JContext = useContext(CustomJewelryContext);
  const [filex, setFilex] = useState<any>();

  const [shouldUpload, setShouldUpload] = useState<boolean>(false);

  const { setImageUrls, imageUrls } = JContext ?? {};

  const props: UploadProps = {
    name: "file",
    // multiple: true,
    action: "https://custom-jewelry-image-uploader-eb7jd5hjrq-ue.a.run.app",

    async customRequest(file) {
      const imageBase64 = await toBase64(file.file);

      if (imageBase64 && filex) {
        if (!shouldUpload) {
          file.onError!({ name: "error", message: "error" });
          return;
        }
        await axios
          .post(file.action, {
            base64: imageBase64,
            fileProperties: filex,
          })
          .then((res) => {
            file.onSuccess!(res.data);
            const newImageUrls: string[] | any[] = imageUrls?.length
              ? [...imageUrls]
              : [];
            newImageUrls.push(res.data.name);
            setImageUrls!(newImageUrls);
          })
          .catch((e) => {
            file.onError!({ name: "error", message: "error" });
          });
      }
    },

    beforeUpload(file, fileList) {
      //   const tempArr = [...fileList!];
      //   tempArr.push(...fileListx);
      //   setFileList(tempArr);
      const isJPEG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpg";
      if (imageUrls?.length === 4) {
        message.error("You have reached the maximum number of files to upload");
        setShouldUpload(false);
        return false;
      }
      setShouldUpload(true);
      if (!isJPG && !isJPEG && !isPNG) {
        message.error("You can only upload Image files");

        setShouldUpload(false);
        // return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("File size greater than 2mb");
        setShouldUpload(false);
        // return false;
      }
      const fileNameArr = file.name.split(".");
      const resolvedFileName = `${fileNameArr[0]}-${file.uid}.${fileNameArr[1]}`;

      setFilex({
        name: resolvedFileName,
        size: file.size,
      });
    },

    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
    onRemove(file) {
      const newImageUrls: string[] | any[] = imageUrls?.length
        ? [...imageUrls]
        : [];
      const deletedIndex = newImageUrls.indexOf(file.response.name);
      newImageUrls.splice(deletedIndex, 1);
      setImageUrls!(newImageUrls);
    },

    maxCount: 4,
  };

  return (
    <FileUploadContainer key={resetUpload}>
      <Dragger {...props} showUploadList style={{ height: "100%" }}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined style={{ color: "#f14156" }} />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Max upload of 4 images</p>
      </Dragger>
    </FileUploadContainer>
  );
};

export default FileUpload;
