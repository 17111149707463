import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CustomJewelry from "./components/organism/CustomJewelry";
import CJContext from "./components/organism/CustomJewelry/Context";
import Main from "./components/organism/Main";

const CustomJewelryMain = () => {
  return (
    <CJContext>
      <CustomJewelry />
    </CJContext>
  );
};

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/customjewelry" Component={CustomJewelryMain} />

          <Route path="/" Component={Main} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
