import React, { useContext, useState } from "react";
import ButtonX from "../../atoms/Button/Index";
import FileUpload from "../../atoms/FileUpload";
import Input from "../../atoms/Input";
import Select from "../../atoms/Select";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

import { message } from "antd";
import * as EmailValidator from "email-validator";
import { CustomJewelryContext } from "./Context";
import {
  Container,
  FormWrapper,
  Header,
  InputWrapper,
  NameWrapper,
  NameWrapperCenter,
} from "./CustomJewelry.styles";
import CustomJewelryText from "../CustomJewelryText/CustomJewelryText";
import { Text } from "../CustomJewelryText/CustomJewelryText.style";

interface ValidatorObjectType {
  fname: string | undefined;
  lname: string | undefined;
  email: string | undefined;
  metalChoice: string | undefined;
  goldKarat: string | undefined;
  imageUrls: string[] | undefined;
}

interface PostDataType extends ValidatorObjectType {
  id: string;
}

const CustomJewelry = () => {
  const JContext = useContext(CustomJewelryContext);
  const [resetUpload, setResetUpload] = useState(Date.now());
  const {
    fname,
    lname,
    email,
    metalChoice,
    goldKarat,
    imageUrls,
    setMetalChoice,
    setGoldKarat,
    setEmail,
    setFname,
    setLname,
    resetForm,
  } = JContext ?? {};

  const metalChoiceOptions = [
    {
      value: "yellowGold",
      label: "Yellow Gold",
    },
    {
      value: "whiteGold",
      label: "White Gold",
    },
    {
      value: "roseGold",
      label: "Rose Gold",
    },
  ];
  const goldKaratOptions = [
    {
      value: "10k",
      label: "10 Karats",
    },
    {
      value: "14k",
      label: "14 Karats",
    },
    {
      value: "18k",
      label: "18 Karats",
    },
    {
      value: "22k",
      label: "22k Karats",
    },
  ];

  const metalChoiceChangeHandler = (value: string) => {
    setMetalChoice && setMetalChoice(value);
  };

  const goldkaratChangeHandler = (value: string) => {
    setGoldKarat && setGoldKarat(value);
  };

  const validate = (obj: ValidatorObjectType) => {
    const { fname, lname, email, metalChoice, goldKarat, imageUrls } =
      obj ?? {};
    if (lname!.length < 2) {
      return {
        formIsReady: false,
        message: "Last Name Length is less than 2 charaters",
      };
    } else if (fname!.length < 2) {
      return {
        formIsReady: false,
        message: "First Name Length is less than 2 charaters",
      };
    } else if (!EmailValidator.validate(email!)) {
      return {
        formIsReady: false,
        message: "Invalid Email",
      };
    } else if (!metalChoice!?.length) {
      return {
        formIsReady: false,
        message: "Please select a Metal",
      };
    } else if (!goldKarat!?.length) {
      return {
        formIsReady: false,
        message: "Please select the gold karat",
      };
    } else if (!imageUrls!?.length) {
      return {
        formIsReady: false,
        message: "Please attach images/photos",
      };
    }

    return {
      formIsReady: true,
      message: "Form is ready",
    };
  };

  const postData = async (data: PostDataType) => {
    const newData = { ...data, date: new Date() };
    axios
      .post(
        "https://custom-jewelry-save-eb7jd5hjrq-ue.a.run.app",
        {
          data: newData,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
      });
    setResetUpload(Date.now());

    return {
      status: "done",
    };
  };

  const formSubmit = () => {
    const obj: ValidatorObjectType = {
      fname,
      lname,
      email,
      metalChoice,
      goldKarat,
      imageUrls,
    };
    const { formIsReady, message: msg } = validate(obj);

    if (formIsReady) {
      const id = uuidv4();
      const data = {
        fname,
        lname,
        email,
        metalChoice,
        goldKarat,
        imageUrls,
        id,
      };
      postData(data).then((res) => {
        if (res.status === "done") {
          message.success("Form successfully sent!");

          resetForm!();
        }
      });
    } else {
      message.error(msg);
    }
  };
  return (
    <Container>
      <Header> Custom Jewelry Design </Header>
      <Text>
        {" "}
        In order to begin the process of creating your personalized jewelry ,
        kindly fill out the provided form and one of Delphi Metals
        representatives will be in touch with you within 1-2 business days.
      </Text>
      <FormWrapper>
        <NameWrapper>
          <InputWrapper>
            <Input
              placeholder="First Name"
              type="text"
              value={fname}
              changeHandler={(e) => setFname!(e)}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              placeholder="Last Name"
              type="text"
              value={lname}
              changeHandler={(e) => setLname!(e)}
            />
          </InputWrapper>
        </NameWrapper>
        <NameWrapper>
          <Input
            placeholder="Email"
            type="email"
            value={email}
            changeHandler={(e) => setEmail!(e)}
          />
        </NameWrapper>
        <NameWrapper>
          <InputWrapper>
            <Select
              placeholder="Metal Choice"
              changeHandler={metalChoiceChangeHandler}
              options={metalChoiceOptions}
              value={metalChoice!}
            />
          </InputWrapper>
          <InputWrapper>
            <Select
              placeholder="Gold Karat"
              changeHandler={goldkaratChangeHandler}
              options={goldKaratOptions}
              value={goldKarat!}
            />
          </InputWrapper>
        </NameWrapper>
        <NameWrapper>
          <FileUpload resetUpload={resetUpload} />
        </NameWrapper>
      </FormWrapper>
      <NameWrapperCenter>
        <ButtonX text="Submit" onClick={formSubmit} bcolor="#f14156" />
      </NameWrapperCenter>
      <CustomJewelryText />
    </Container>
  );
};

export default CustomJewelry;
