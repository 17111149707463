import React, { FC } from "react";
import { StyledComponent } from "styled-components";
import { SelectC } from "./Select.style";

interface OptionsType {
  value: string;
  label: string;
}
interface SelectProps {
  placeholder: string;
  changeHandler: (value: string) => void;
  width?: string;
  options: OptionsType[];
  CustomStyle?: StyledComponent<"div", any, {}, never>;
  value: string;
}

const Select: FC<SelectProps> = ({
  placeholder,
  changeHandler,
  width = "100%",
  options,
  CustomStyle,
  value,
}) => {
  if (CustomStyle) {
    return (
      <CustomStyle>
        <SelectC
          placeholder={placeholder}
          onChange={(e) => changeHandler(e as string)}
          width={width}
          size="large"
          options={options}
          value={value}
        />
      </CustomStyle>
    );
  }
  return (
    <SelectC
      placeholder={placeholder}
      onChange={(e) => changeHandler(e as string)}
      width={width}
      size="large"
      options={options}
      value={value}
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};

export default Select;
