import React from "react";
import { Container, Header, Text } from "./CustomJewelryText.style";

const CustomJewelryText = () => {
  return (
    <Container>
      <Header> DESIGN YOUR OWN JEWELRY IN 3 SIMPLE STEPS</Header>
      <Text>
        Designing your own jewelry is an exciting and rewarding process that
        allows you to create a unique piece that is tailored to your personal
        taste and style. Whether you're looking to design an engagement ring, a
        pendant, or a pair of earrings, the process can seem daunting at first.
        However, with a little guidance and inspiration, you can easily create
        your own one-of-a-kind piece of jewelry in just three simple steps.
      </Text>
      <Header>Step 1: Inspiration and Sketching </Header>
      <Text>
        The first step in designing your own jewelry is to find inspiration for
        your design. Look online, in magazines, or even around your neighborhood
        for inspiration. Take note of the styles, colors, and materials that
        catch your eye. You can even visit a local jewelry store to get a sense
        of the different types of jewelry that are available.
      </Text>
      <Text>
        Once you have an idea of what you want, it's time to start sketching.
        Grab a pencil and paper and begin to sketch out your design. Don't worry
        about getting it perfect – this is just a starting point. Sketch out
        different angles and perspectives to help you get a better sense of the
        design.
      </Text>
      <Header>Step 2: Material Selection and Design Refinement</Header>
      <Text>
        The next step is to select the materials you want to use for your
        jewelry. Consider the type of metal, stones, and other embellishments
        you want to include. It's important to keep in mind the budget you have
        for the project.
      </Text>
      <Text>
        Once you have your materials selected, it's time to refine your design.
        Look at your sketches and think about what changes you want to make.
        Consider the size, shape, and placement of the materials you've chosen.
        This is also a good time to consult with a jewelry designer or a trusted
        friend for feedback and suggestions.
      </Text>
      <Header>Step 3: Jewelry Production</Header>
      <Text>
        The final step in designing your own jewelry is to produce the piece.
        Depending on your skill level, you may want to work with a professional
        jewelry maker to bring your design to life. Alternatively, you can try
        your hand at creating the jewelry yourself by taking a jewelry-making
        class or using online tutorials.
      </Text>
      <Text>
        During the production process, it's important to be flexible and open to
        making adjustments as needed. The final product may look slightly
        different from your initial design, but this is all part of the creative
        process. Once your jewelry is complete, take a moment to admire your
        work and wear it with pride.
      </Text>
      <Text>
        In conclusion, designing your own jewelry is a fun and creative process
        that can result in a unique piece of jewelry that is tailored to your
        personal style. By following these three simple steps, you can easily
        design and create your own jewelry. With a little patience, creativity,
        and a willingness to learn, you'll have a piece of jewelry that you can
        treasure for years to come.
      </Text>
    </Container>
  );
};

export default CustomJewelryText;
