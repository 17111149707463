import React, { FC } from "react";
import { StyledComponent } from "styled-components";
import { InputC } from "./Input.style";

interface InputProps {
  placeholder: string;
  type: string;
  changeHandler: (e: string) => void;
  value: string | undefined;
  width?: string;
  CustomStyle?: StyledComponent<"div", any, {}, never>;
}

const Input: FC<InputProps> = ({
  placeholder,
  type,
  changeHandler,
  value,
  width = "100%",
  CustomStyle,
}) => {
  if (CustomStyle) {
    return (
      <CustomStyle>
        <InputC
          placeholder={placeholder}
          type={type}
          onChange={(e) => changeHandler(e.target.value)}
          value={value}
          width={width}
          size="large"
        />
      </CustomStyle>
    );
  }
  return (
    <InputC
      placeholder={placeholder}
      type={type}
      onChange={(e) => changeHandler(e.target.value)}
      value={value}
      width={width}
      size="large"
    />
  );
};

export default Input;
