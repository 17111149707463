import styled from "styled-components";
import { Button } from "antd";

export const ButtonC = styled(Button)<{
  fcolor?: string;
  width?: string;
  bcolor?: string;
}>`
  width: ${({ width }) => width};
  height: 40px;
  background: ${({ bcolor }) => bcolor};
  color: ${({ fcolor }) => fcolor};
`;
