import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.div`
  font-family: "Merriweather";
  font-size: 24px;
  color: #000050;
  &:first-child {
    margin-top: 50px;
  }
`;

export const Text = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  font-family: "Maitree";
  font-size: 16px;
`;
